import { Address, TransactionReceipt, WriteContractReturnType, hexToBigInt } from 'viem';
import { Config, useSendTransaction, useWaitForTransactionReceipt, useWriteContract } from 'wagmi';
import { WriteContractVariables } from 'wagmi/query';
import { ToastId, Text, Link } from '@chakra-ui/react';
import { TransactionType, getToastTransactionHeadline } from '~/app/components/toast/toast-util';
import { TransactionStatusToast } from '~/app/components/toast/TransactionStatusToast';
import { useRef } from 'react';
import { useAddRecentTransaction } from '@rainbow-me/rainbowkit';
import { makeVar } from '@apollo/client';
import { toBigInt } from './toBigInt';
import { ToastType, useAppToast } from '~/app/components/toast/AppToast';
import useNetworkConfig from '../global/useNetworkConfig';
interface UseContractWriteMutationArgs {
  args: unknown[];
  value?: BigInt;
  gas?: BigInt;
}
type LocalWriteConfig = WriteContractVariables<readonly unknown[], string, readonly unknown[], Config, number>;
interface Props {
  config: LocalWriteConfig;
  transactionType: TransactionType;
}
interface RawTransactionArgs {
  data: string;
  to: string;
  value?: string | bigint;
  account?: string;
  gas: number | bigint;
  gasLimit?: number | bigint;
  nonce?: number;
  toastText: string;
  walletText?: string;
}
export interface TransactionQuery {
  isSubmitting: boolean;
  submitError: Error | null;
  isSubmitError: boolean;
  isPending: boolean;
  isConfirmed: boolean;
  isFailed: boolean;
  error: Error | null;
  reset: () => void;

  // txResponse?: TransactionResponse;
  txReceipt?: TransactionReceipt;
}
export interface SubmitTransactionQuery extends TransactionQuery {
  submit: (config: UseContractWriteMutationArgs & {
    toastText: string;
    walletText?: string;
  }) => void;
  submitAsync: (config: UseContractWriteMutationArgs & {
    toastText: string;
    walletText?: string;
  }) => Promise<any>; // TODO: Type this right for viem
  submitRawTransaction: (params: RawTransactionArgs) => void;
}
export const txPendingVar = makeVar(false);
export function useSubmitTransaction({
  config,
  transactionType
}: Props): SubmitTransactionQuery {
  const networkConfig = useNetworkConfig();
  const explorerURL = networkConfig.etherscanUrl;
  const {
    showToast,
    updateToast,
    removeToast
  } = useAppToast();
  const toastIdRef = useRef<ToastId | undefined>();
  const toastText = useRef<string>('');
  const walletText = useRef<string>('');
  const addRecentTransaction = useAddRecentTransaction();
  function getExplorerURL(hash) {
    const explorerBaseURL = networkConfig.etherscanUrl; // Adjust this to dynamically use the correct base URL
    return `${explorerBaseURL}/tx/${hash}`;
  }
  const txConfig = {
    mutation: {
      onMutate() {
        txPendingVar(true);
      },
      onSuccess(data: WriteContractReturnType, variables, context) {
        // console.log('data in toast onSuccess', data);
        showToast({
          id: data,
          badge: getToastTransactionHeadline(transactionType, 'PENDING'),
          content: <Text>{toastText.current}</Text>,
          type: ToastType.Loading,
          auto: true
        });
        try {
          addRecentTransaction({
            hash: data,
            description: walletText.current
          });
        } catch {
          //TODO: need to handle this gracefully, can happen when user has too many recent transactions
          console.error('addRecentTransaction failed');
        }

        // txPendingVar(true);

        if (config?.onSuccess) {
          return config.onSuccess(data, variables, context);
        }
      },
      onError(error) {
        console.log(error);
        txPendingVar(false);
      },
      onSettled(data, error, variables, context) {
        if (error) {
          setTimeout(function () {
            updateToast(data || '', {
              type: ToastType.Error,
              badge: getToastTransactionHeadline(transactionType, 'ERROR'),
              content: <Text>{toastText.current}</Text>,
              auto: true
            });
          }, 2000);
        } else {
          setTimeout(function () {
            updateToast(data || '', {
              type: ToastType.Success,
              badge: getToastTransactionHeadline(transactionType, 'CONFIRMED'),
              content: <Text color="text.900">
                  {toastText.current}
                  <Link color="inherit" href={getExplorerURL(data)} isExternal>
                    View Transaction Receipt
                  </Link>
                </Text>,
              auto: true
            });
          }, 2000);
        }
        txPendingVar(false);
        if (config?.onSettled) {
          return config.onSettled();
        }
      }
    }
  };
  const {
    sendTransaction,
    data: rawHash,
    isError: isRawError,
    isPending: isRawPending,
    error: rawError,
    reset: rawReset
  } = useSendTransaction(txConfig);
  const {
    writeContract,
    writeContractAsync,
    data: hash,
    error,
    isError,
    isPending,
    reset
  } = useWriteContract(txConfig);
  const waitForTransaction = useWaitForTransactionReceipt({
    hash: hash || rawHash
  });
  function submit(params: UseContractWriteMutationArgs & {
    toastText: string;
    walletText?: string;
  }) {
    toastText.current = params.toastText;
    walletText.current = params.walletText || config.toastText;
    writeContract({
      ...config,
      args: params.args,
      gas: params.gas,
      //@ts-ignore
      value: params.value
    });
  }
  async function submitAsync(params: UseContractWriteMutationArgs & {
    toastText: string;
    walletText?: string;
  }) {
    toastText.current = params.toastText;
    walletText.current = params.walletText || config.toastText;
    return writeContractAsync({
      ...config,
      args: params.args,
      gas: params.gas,
      //@ts-ignore
      value: params.value
    });
  }
  function submitRawTransaction(params: RawTransactionArgs) {
    toastText.current = params.toastText;
    walletText.current = params.walletText || params.toastText;
    sendTransaction({
      to: params.to as Address,
      data: params.data,
      value: hexToBigInt(params.value as Address),
      gas: params.gas ? toBigInt(params.gas) : undefined,
      gasLimit: params.gasLimit ? toBigInt(params.gasLimit) : undefined,
      nonce: params.nonce
    });
  }
  return {
    submit,
    submitAsync,
    submitRawTransaction,
    isSubmitting: isPending || isRawPending,
    submitError: error || rawError,
    isSubmitError: isError || isRawError,
    isPending: waitForTransaction.isLoading,
    isConfirmed: waitForTransaction.isSuccess && waitForTransaction.data?.status !== 0 && waitForTransaction.error === null,
    isFailed: waitForTransaction.isError || waitForTransaction.error !== null || waitForTransaction.data?.status === 0,
    error: waitForTransaction.error,
    reset: () => {
      if (rawHash) {
        return rawReset();
      }
      reset();
    },
    // txResponse: contractWrite.data,
    txReceipt: waitForTransaction.data ? waitForTransaction.data[hash] : undefined
  };
}